import { KatLabel , KatWorkflowtracker } from "@amzn/katal-react";
import React, { FunctionComponent, useState, useEffect } from "react";
import $style from './common-component.module.scss';

type Props = {
}

export const HeaderComponent: FunctionComponent<Props> = ({ }) => {

    const [steps, setSteps] = useState([
        {
            state: "in-progress",
            label: "SOP Selection",
            secondaryLabel: "Select an SOP",
            clickable: false,
            id: 0,
        },
        {
            state: "todo",
            label: "SOP tasks",
            secondaryLabel: "Implement tasks and select outcomes",
            clickable: false,
            id: 1,
        },
        {
            state: "todo",
            label: "Resolve",
            secondaryLabel: "Send reply to Seller",
            clickable: false,
            id: 2,
        },
    ]);

    useEffect(() => {
    })

    return (
        <div className={$style.displayHeader}>
            <h5>SOP Generator</h5>
            <KatLabel>Choose the SOP that best aligns with this issue and click 'Next' to generate a series of
                step-by-step tasks to resolve it. If you prefer not to proceed with the task, click 'Cancel'.
            </KatLabel>
            {/* Initial mock had a progress bar, commenting that code, incase it has to be used again. */}
            {/* <KatWorkflowtracker
                steps={steps}
                className={$style.headerButtons}
            /> */}
        </div>
    );
};