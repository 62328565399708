import React, { useEffect, useState } from 'react';
import { KatAlert, KatBox, KatLink } from '@amzn/katal-react';
import { paragonCoreDataContext } from "@amzn/paragon-context/lib/ParagonCoreDataContext";
import { paragonWidgetDataContext } from '@amzn/paragon-context/lib/ParagonWidgetDataContext';
import { paragonAnalyticsContext } from "@amzn/paragon-context/lib/ParagonAnalyticsContext";
import {
    PageSection,
    useParagonContextConsumerEl,
    useParagonContext
} from "@amzn/paragon-ui-react";
import $style from "./action-plan-widget.module.scss";
import { SOPDetails } from './components/sopListDetails/SOPDetails';
import { ActivityList } from './components/ActionsListDetails/ActivityList';
import { SelectionFooter } from './components/common/SelectionFooter';
import { FinalBlurb } from './components/ReplySeller';
import { HeaderComponent } from './components/common/HeaderComponent';
import { getActivityStep } from './service/actionPlan';
import { GetNextSOPStepResponseData } from './model/ActionPlanTypes';
import { getParagonDomain, logClickStreamData } from 'src/common/utils';
import { logger } from 'src/logger';

export const ActionPlanWidget = ({ }) => {
    const [isSOPPicked, setIsSOPPicked] = useState(false);
    const [selectedSopId, setSelectedSopId] = useState<string>("");
    const [paragonCaseId, setParagonCaseId] = useState<string | undefined>();
    const [paragonCaseSubject, setParagonCaseSubject] = useState<string | undefined>();
    const [showSOPList, setShowSOPList] = useState(true);
    const [selectedActivity, setSelectedActivity] = useState("");
    const [loadingActions, setLoadingActions] = useState(true);
    const [showActionsList, setShowActionsList] = useState(false);
    const [actions, setActions] = useState<any>();
    const [actionResponse, setActionResponse] = useState<any>();
    const [command, setCommand] = useState<string>("");
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [noActionsListSupport, setNoActionsListSupport] = useState(false);
    const [showFinalBlurb, setShowFinalBlurb] = useState(false);
    const [sopListFeedback, setSopListFeedback] = useState('');

    const domain: string = getParagonDomain(window.location.origin);

    const el = useParagonContextConsumerEl();
    const {
        value: { caseDetails },
    } = useParagonContext(el, paragonCoreDataContext);

    const { methods: widgetMethods } = useParagonContext(el, paragonWidgetDataContext);

    const { methods: clickStreamFunctions } = useParagonContext(el, paragonAnalyticsContext);

    function changeSOPId(value: string) {
        setSelectedSopId(value);
    }

    function getNextActivityStep() {
        setLoadingActions(true)
        // Limited SOPs are onboarded. To test, replace selectedSopId with G4DL5CCX7CFC4Q9U until follow up CR
        getActivityStep(selectedSopId, widgetMethods, actionResponse, selectedActivity).then((response) => {
            setShowErrorBanner(false);
            if (response.success) {
                const getNextSOPStepResponse: GetNextSOPStepResponseData = response.data;
                const action = getNextSOPStepResponse.actions;
                setActionResponse(action);
                setCommand(getNextSOPStepResponse.command);
                if (!command && action === "null") {
                    setNoActionsListSupport(true)
                }
                setActions(JSON.parse(action || "{}"));
            } else {
                setShowErrorBanner(true);
            }
        }).catch(() => {
            console.log("Actions fetch failed");
            setShowErrorBanner(true);
        }).finally(() => {
            setLoadingActions(false)
        })
    };

    function handleNextButton() {
        if (selectedSopId != "" && isSOPPicked) {
            logClickStreamData(clickStreamFunctions, "click", "action-plan-widget-sop-picked", {
                caseId: caseDetails?.id,
                sopId: selectedSopId
            });
            logger.info(`[ActionPlan] SOP picked for case: ${caseDetails?.id} is ${selectedSopId}`);
            // getNextActivityStep();
            // setShowSOPList(false);
            // setShowActionsList(true);
        } else if (!isSOPPicked){
            setShowFinalBlurb(true);
            setShowSOPList(false);
            logClickStreamData(clickStreamFunctions, "click", "action-plan-widget-sop-not-listed", {
                caseId: caseDetails?.id,
                sopIdProvided: sopListFeedback
            });
            logger.info(`[ActionPlan] for case: ${caseDetails?.id} SOP isn't listed. Feedback: ${sopListFeedback}`);
        }
    }

    function handleCancelButton() {
        setSelectedSopId("");
        setShowActionsList(false);
        setShowFinalBlurb(true);
        setNoActionsListSupport(false);
        setShowSOPList(false);
    }

    function changeCancelReason(value: string) {
        console.log(value);
    }

    function changeSopListFeedback(value: string) {
        setSopListFeedback(value);
    }

    function setIfSOPListed(value: boolean){
        setIsSOPPicked(value);
    }

    const handleActivitySelection = (activity: string) => {
        setSelectedActivity(activity);
    }

    function handleBackButton() {
        if (!actions || actions === null){
            setShowSOPList(true);
            setNoActionsListSupport(false);
            setShowFinalBlurb(false);
        } else{
            actions.splice(actions.length - 1);
            if (actions.length === 0) {
                setShowSOPList(true)
                setNoActionsListSupport(false);
                setSelectedActivity("")
                setActionResponse(undefined)
                setActions(undefined)
            } else {
                setShowSOPList(false);
                setActions([...actions]);
                setActionResponse(JSON.stringify(actions));
            }
        }
    }

    useEffect(() => {
        if (!caseDetails?.id) {
            return;
        }
        setParagonCaseId(caseDetails?.id);
        setParagonCaseSubject(caseDetails?.subject);

        logger.info(`Action Plan widget is loaded for case: ${caseDetails?.id}`);
        logClickStreamData(clickStreamFunctions, "click", "action-plan-widget-load");

    }, [caseDetails?.id, caseDetails?.subject]);

    return (
        <PageSection
            title="Action Plan"
            widgetId="action-plan-widget"
        >
            <div className={$style.actionPlanWidget}>
                <HeaderComponent />
                {showSOPList && <SOPDetails
                    paragonCaseId={paragonCaseId}
                    paragonCaseSubject={paragonCaseSubject}
                    widgetMethods={widgetMethods}
                    changeSOPId={changeSOPId}
                    changeSopListFeedback={changeSopListFeedback}
                    setIfSOPListed={setIfSOPListed}
                    isSOPPicked={isSOPPicked}
                />}

                {showActionsList && !noActionsListSupport && <ActivityList
                    actions={actions}
                    loading={loadingActions}
                    onActivitySelection={handleActivitySelection}
                />}

                {noActionsListSupport &&
                    <KatBox variant="white" className={$style.mainBox}>
                        <label>Stepping in to actions is currently not supported for this SOP.
                            Please refer to SOP link and go though regular procress</label>
                        <KatLink
                            href={domain + "/hz/codex?id=" + selectedSopId}
                            target="_blank"
                            label="LINK"
                        />
                    </KatBox>
                }

                {showErrorBanner && <KatAlert variant='danger' className={$style.errorBanner}
                    description='Error loading, try again...'
                // dismissed={isSOPPicked}
                />}

                {(command === "END" || showFinalBlurb) && <FinalBlurb
                    command={command}
                />}
                <SelectionFooter
                    handleNextButton={handleNextButton}
                    handleSubmitButton={handleCancelButton}
                    handleBackButton={handleBackButton}
                    changeCancelReason={changeCancelReason}
                    handleResolveButton={() => { }}
                    command={command}
                    isSOPPicked={isSOPPicked}
                    disableNext={showFinalBlurb}
                />

            </div>
        </PageSection>
    );
};